@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

/* Italic */
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-ExtraLightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-SemiBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-ExtraBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/Nunito-Black.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
}
/* Italic */
